import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import RefinanceForm from "../components/Form/Refinance";

const Page = ({ data }) => {
  return (
    <Layout hideHeader={true} hideFooter={true}>
      <SearchEngineOptimization
        title=""
        description=""
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="absolute z-10 flex w-full items-center justify-between px-4 py-4 lg:px-10 lg:py-8">
          <Link to="/">
            <StaticImage
              src="../images/Global/Logo-Dark.png"
              alt="Right Start Mortgage Logo"
              loading="eager"
              width={189}
            />
          </Link>

          <Link to="/request-rates/">
            <i className="fal fa-times text-xl text-black transition-all duration-300 ease-linear hover:text-primary-500"></i>
          </Link>
        </div>
        <div className="grid h-screen lg:grid-cols-12">
          <div className="hidden flex-col items-start justify-between bg-primary-50 py-12 lg:col-span-3 lg:col-start-1 lg:flex lg:px-10 lg:pt-32 lg:pb-12">
            <div>
              <p className="text-sm">
                Let’s get the ball rolling so you can refinance your home! We
                have a few basic questions to help us get started. Or, if you’d
                like immediate assistance, please give us a call at{" "}
                <a
                  href="tel:800-520-5626"
                  className="whitespace-nowrap text-primary-500 no-underline"
                >
                  (800) 520-5626
                </a>
                .
              </p>
            </div>
          </div>
          <div className="relative mt-14 py-8 px-4 lg:col-span-9 lg:col-end-13 lg:mt-0 lg:py-12 lg:px-20">
            <div className="relative h-full">
              <RefinanceForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;
export default Page;
